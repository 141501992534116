export async function fetchData() {
  try {
    const response = await fetch(
      "https://raw.githubusercontent.com/zuzannamj/data-views-app/main/structure.json"
    );

    // Check if the response was ok (status code in the range 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return Array.isArray(data.data) ? data.data : [];
  } catch (error) {
    console.error("Failed to fetch data from remote source:", error.message);

    // Attempt to fetch local copy
    try {
      const response = await fetch('/structure.json');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const localData = await response.json();
      return Array.isArray(localData.data) ? localData.data : [];
    } catch (localError) {
      console.error("Failed to fetch local data:", localError.message);
      return [];
    }
  }
}
