import { useState, useEffect } from 'react';

const QueryGenerator = ({ checkedTables, updateQuery, tableStructures, onSpecialJoinApplied }) => { 
  const [specialJoinApplied, setSpecialJoinApplied] = useState(null);
  useEffect(() => {

    console.clear();
    console.log("Looking for clues? You’re just in time for the scavenger hunt!");
    
  

    // Predefined order of tables for joining
    const joinTables = ['SendLog', 'SMSSendLog', '_Sent', '_Open', '_Click', '_Bounce', '_Unsubscribe','_Complaint', '_FTAF', '_BusinessUnitUnsubscribes', '_Subscribers','_ListSubscribers','_Job','_JourneyActivity','_Journey','_AutomationInstance','_AutomationActivityInstance', '_SMSMessageTracking', '_SMSSubscriptionLog', '_UndeliverableSms'];

    // Fields that are always aliased
    const fieldsAlwaysAliased = ['EventDate', 'CreatedDate', 'DateUnsubscribed', 'Status', 'SubscriberType'];
 
// Group join conditions
const groupJoinConditions = {
  group0: (baseTable, t) => `
    ON ${baseTable}.JobID = ${t}.JobID 
    AND ${baseTable}.ListID = ${t}.ListID 
    AND ${baseTable}.BatchID = ${t}.BatchID 
    AND ${baseTable}.SubID = ${t}.SubscriberID`,

  group1: (baseTable, t) => {
    let joinCondition = `
    ON ${baseTable}.JobID = ${t}.JobID 
    AND ${baseTable}.ListID = ${t}.ListID 
    AND ${baseTable}.BatchID = ${t}.BatchID`;

    if (baseTable === 'SendLog') {
      joinCondition += `
    AND ${baseTable}.SubID = ${t}.SubscriberID`;
    } else {
      joinCondition += `
    AND ${baseTable}.SubscriberID = ${t}.SubscriberID`;
    }

    if (t !== '_Sent') {
      joinCondition += `
    AND ${t}.IsUnique = 1`;
    }

    return joinCondition;
  },

  group2: (baseTable, t) => {
    let joinCondition = `
    ON ${baseTable}.JobID = ${t}.JobID 
    AND ${baseTable}.ListID = ${t}.ListID 
    AND ${baseTable}.BatchID = ${t}.BatchID`;

    if (baseTable === 'SendLog') {
      joinCondition += `
    AND ${baseTable}.SubID = ${t}.SubscriberID`;
    } else {
      joinCondition += `
    AND ${baseTable}.SubscriberID = ${t}.SubscriberID`;
    }

    if (t !== '_Sent') {
      joinCondition += `
    AND ${t}.IsUnique = 1`;
    }

    return joinCondition;
  },

  group3: (baseTable, t) => `
    ${baseTable === 'SendLog' 
      ? `ON ${baseTable}.SubID = ${t}.SubscriberID` 
      : `ON ${baseTable}.SubscriberID = ${t}.SubscriberID`
    }`,

  group4: (baseTable, t) => `
    ${baseTable === 'SendLog' 
      ? `ON ${baseTable}.TriggeredSendID = _JourneyActivity.JourneyActivityObjectID` 
      : `ON ${baseTable}.TriggererSendDefinitionObjectID = _JourneyActivity.JourneyActivityObjectID`
    }`,

  group5: () => `
    ON _JourneyActivity.VersionID = _Journey.VersionID`,

  group6: (baseTable, t) => `
    ON ${baseTable}.JobID = ${t}.JobID`,

  group7: (baseTable, t) => `
    ON ${baseTable}.AutomationCustomerKey = ${t}.AutomationCustomerKey`,
  
  group9: (baseTable, t) => `
    ON ${baseTable}.SubID = ${t}.SubscriberID
    AND ${baseTable}.SMSJobID = ${t}.SMSJobID 
    AND ${baseTable}.SMSBatchID = ${t}.SMSBatchID`,

    group10: (baseTable, t) => {
      let joinCondition = '';
    
      if (baseTable === 'SMSSendLog') {
        joinCondition = `
    ON _SMSMessageTracking.Mobile = ${t}.MobileNumber`;
      } else if (baseTable === '_SMSMessageTracking') {
        joinCondition = `
    ON ${baseTable}.Mobile = ${t}.MobileNumber`;
      } else {
        joinCondition = `
    ON ${baseTable}.MobileNumber = ${t}.MobileNumber`;
      }
    
      return joinCondition;
    }    

};

    // Determine group for a table
    const determineGroup = (tableName) => {
      const groupMappings = {
        'SendLog': 'group0',
        '_Sent': 'group1',
        '_Open': 'group1',
        '_Bounce': 'group1',
        '_Click': 'group1',
        '_FTAF': 'group1',
        '_Unsubscribe': 'group2',
        '_Complaint': 'group2',
        '_BusinessUnitUnsubscribes': 'group3',
        '_Subscribers': 'group3',
        '_ListSubscribers': 'group3',
        '_JourneyActivity': 'group4',
        '_Journey': 'group5',
        '_Job': 'group6',
        '_AutomationInstance': 'group7',
        '_AutomationActivityInstance': 'group7',
        'SMSSendLog': 'group8',
        '_SMSMessageTracking': 'group9',
        '_SMSSubscriptionLog': 'group10',
        '_UndeliverableSms': 'group10'
      };

      const group = groupMappings[tableName] || 'unknown';
      //console.log('Determined group for', tableName, ':', group);
    
      return group;
    };

    const specialJoinRules = {
      rule1: (baseTable, checkedTableNames) => {
        const isGroup1Checked = checkedTableNames.some(tableName => determineGroup(tableName) === 'group1');
        const isGroup5Checked = checkedTableNames.includes('_Journey');
        const isGroup4NotChecked = !checkedTableNames.includes('_JourneyActivity');
        const isGroup8NotChecked = !checkedTableNames.includes('SendLog')
    
        return isGroup1Checked && isGroup5Checked && isGroup4NotChecked && isGroup8NotChecked
               ? `LEFT JOIN _JourneyActivity\n    ON ${baseTable}.TriggererSendDefinitionObjectID = _JourneyActivity.JourneyActivityObjectID` //\nLEFT JOIN _Journey ON _JourneyActivity.VersionID = _Journey.VersionID
               : '';
      },
      rule18: (baseTable, checkedTableNames) => { //check all triggering combinations
        const isGroup3Checked = determineGroup(baseTable) === 'group3';
        const isGroup6Checked = checkedTableNames.some(tableName => determineGroup(tableName) === 'group6');
        const areGroups1And2NotChecked = !checkedTableNames.some(tableName => ['group1', 'group2'].includes(determineGroup(tableName)));
        const isGroup4Checked = checkedTableNames.includes('_JourneyActivity');
        const isJourneyChecked = checkedTableNames.includes('_Journey');
        const isGroup8NotChecked = !checkedTableNames.includes('SendLog')
      
        if ((isGroup3Checked && isGroup6Checked && areGroups1And2NotChecked && isGroup4Checked && isGroup8NotChecked) || (isGroup3Checked && isGroup6Checked && areGroups1And2NotChecked && !isGroup4Checked && isJourneyChecked && isGroup8NotChecked)) {
          let joinStatement = `LEFT JOIN _Sent\n    ON _Sent.SubscriberID = ${baseTable}.SubscriberID\nLEFT JOIN _Job\n    ON _Job.JobID = _Sent.JobID\nLEFT JOIN _JourneyActivity\n    ON _Job.TriggererSendDefinitionObjectID = _JourneyActivity.JourneyActivityObjectID`;
          if (isJourneyChecked) {
            joinStatement += `\nLEFT JOIN _Journey\n    ON _JourneyActivity.VersionID = _Journey.VersionID`;
          }
          return joinStatement;
        }
      
        return '';
      },  
      rule2: (baseTable, checkedTableNames) => { //do naprawy zwykla logika nie powinna sie trigerowac
        const isGroup3Checked = determineGroup(baseTable) === 'group3';
        const isGroup6Checked = checkedTableNames.some(tableName => determineGroup(tableName) === 'group6');
        const areGroups1And2NotChecked = !checkedTableNames.some(tableName => ['group1', 'group2'].includes(determineGroup(tableName)));
        const isGroup8NotChecked = !checkedTableNames.includes('SendLog')
    
        return isGroup3Checked && isGroup6Checked && areGroups1And2NotChecked && isGroup8NotChecked
               ? `LEFT JOIN _Sent\n    ON _Sent.SubscriberID = ${baseTable}.SubscriberID\nLEFT JOIN _Job\n    ON _Job.JobID = _Sent.JobID`
               : '';
      },
      rule3: (baseTable, checkedTableNames) => { 
        const isGroup2Checked = determineGroup(baseTable) === 'group2';
        const isGroup4Checked = checkedTableNames.includes('_JourneyActivity');
        const areGroups1And6NotChecked = !checkedTableNames.some(tableName => ['group1', 'group6'].includes(determineGroup(tableName)));
        const isGroup8NotChecked = !checkedTableNames.includes('SendLog')
    
        return isGroup2Checked && isGroup4Checked && areGroups1And6NotChecked && isGroup8NotChecked
               ? `LEFT JOIN _Job\n    ON _Job.JobID = ${baseTable}.JobID\nLEFT JOIN _JourneyActivity\n    ON _Job.TriggererSendDefinitionObjectID = _JourneyActivity.JourneyActivityObjectID`
               : '';
      },
      rule35: (baseTable, checkedTableNames) => { 
        const isGroup2Checked = determineGroup(baseTable) === 'group2';
        const isGroup4Checked = checkedTableNames.includes('_JourneyActivity');
        const isGroup6Checked = checkedTableNames.some(tableName => determineGroup(tableName) === 'group6');
        const areGroups1And6NotChecked = !checkedTableNames.some(tableName => ['group1'].includes(determineGroup(tableName)));
        const isGroup8NotChecked = !checkedTableNames.includes('SendLog')
    
        return isGroup2Checked && isGroup4Checked && isGroup6Checked && areGroups1And6NotChecked && isGroup8NotChecked
               ? `LEFT JOIN _Job\n    ON _Job.JobID = ${baseTable}.JobID\nLEFT JOIN _JourneyActivity\n    ON _Job.TriggererSendDefinitionObjectID = _JourneyActivity.JourneyActivityObjectID`
               : '';
      },
      rule36: (baseTable, checkedTableNames) => { 
        const isGroup2Checked = determineGroup(baseTable) === 'group2';
        const isGroup5Checked = checkedTableNames.includes('_Journey');
        const isGroup6Checked = checkedTableNames.some(tableName => determineGroup(tableName) === 'group6');
        const areGroups1And6NotChecked = !checkedTableNames.some(tableName => ['group1'].includes(determineGroup(tableName)));
        const isGroup8NotChecked = !checkedTableNames.includes('SendLog')
    
        return isGroup2Checked && isGroup5Checked && isGroup6Checked && areGroups1And6NotChecked && isGroup8NotChecked
               ? `LEFT JOIN _Job\n    ON _Job.JobID = ${baseTable}.JobID\nLEFT JOIN _JourneyActivity\n    ON _Job.TriggererSendDefinitionObjectID = _JourneyActivity.JourneyActivityObjectID`
               : '';
      },
      rule4: (baseTable, checkedTableNames) => {
        const isGroup2Checked = determineGroup(baseTable) === 'group2';
        const isGroup5Checked = checkedTableNames.includes('_Journey');
        const areGroups1And4And6NotChecked = !checkedTableNames.some(tableName => ['group1', 'group4', 'group6'].includes(determineGroup(tableName)));
        const isGroup8NotChecked = !checkedTableNames.includes('SendLog')
    
        return isGroup2Checked && isGroup5Checked && areGroups1And4And6NotChecked && isGroup8NotChecked
               ? `LEFT JOIN _Job\n    ON _Job.JobID = ${baseTable}.JobID\nLEFT JOIN _JourneyActivity\n    ON _Job.TriggererSendDefinitionObjectID = _JourneyActivity.JourneyActivityObjectID` //\nLEFT JOIN _Journey ON _JourneyActivity.VersionID = _Journey.VersionID
               : '';
      },
      rule5: (baseTable, checkedTableNames) => {
        const isGroup3Checked = determineGroup(baseTable) === 'group3';
        const isGroup4Checked = checkedTableNames.includes('_JourneyActivity');
        const isGroup1NotChecked = !checkedTableNames.some(tableName => determineGroup(tableName) === 'group1');
        const areGroups2And6NotChecked = !checkedTableNames.some(tableName => ['group2', 'group6'].includes(determineGroup(tableName)));
        const isGroup8NotChecked = !checkedTableNames.includes('SendLog')
    
        return isGroup3Checked && isGroup4Checked && (isGroup1NotChecked || areGroups2And6NotChecked) && isGroup8NotChecked
               ? `LEFT JOIN _Sent\n    ON _Sent.SubscriberID = ${baseTable}.SubscriberID\nLEFT JOIN _JourneyActivity\n    ON _Sent.TriggererSendDefinitionObjectID = _JourneyActivity.JourneyActivityObjectID`
               : '';
      },
      rule6: (baseTable, checkedTableNames) => {
        const isGroup3Checked = determineGroup(baseTable) === 'group3';
        const isGroup5Checked = checkedTableNames.includes('_Journey');
        const areGroups1And4NotChecked = !checkedTableNames.some(tableName => ['group1', 'group4'].includes(determineGroup(tableName)));
        const areGroups2And4And6NotChecked = !checkedTableNames.some(tableName => ['group2', 'group4', 'group6'].includes(determineGroup(tableName)));
        const isGroup8NotChecked = !checkedTableNames.includes('SendLog')
    
        return isGroup3Checked && isGroup5Checked && (areGroups1And4NotChecked || areGroups2And4And6NotChecked) && isGroup8NotChecked
               ? `LEFT JOIN _Sent\n    ON _Sent.SubscriberID = ${baseTable}.SubscriberID\nLEFT JOIN _JourneyActivity\n    ON _Sent.TriggererSendDefinitionObjectID = _JourneyActivity.JourneyActivityObjectID` //\nLEFT JOIN _Journey ON _JourneyActivity.VersionID = _Journey.VersionID
               : '';
      },
      rule7: (baseTable, checkedTableNames) => {
        const isGroup6Checked = determineGroup(baseTable) === 'group6';
        const isGroup5Checked = checkedTableNames.includes('_Journey');
        const isGroup4NotChecked = !checkedTableNames.includes('_JourneyActivity');
        const isGroup8NotChecked = !checkedTableNames.includes('SendLog')
    
        return isGroup6Checked && isGroup5Checked && isGroup4NotChecked && isGroup8NotChecked
               ? `LEFT JOIN _JourneyActivity\n    ON _Job.TriggererSendDefinitionObjectID = _JourneyActivity.JourneyActivityObjectID\nLEFT JOIN _Journey\n    ON _JourneyActivity.VersionID = _Journey.VersionID`
               : '';
      },
      rule12sl: (baseTable, checkedTableNames) => { //ok
        //const isGroup1NotChecked = !checkedTableNames.some(tableName => determineGroup(tableName) === 'group1');
        const isGroup8Checked = checkedTableNames.includes('SendLog');
        const isGroup5Checked = checkedTableNames.includes('_Journey');
        const isGroup4NotChecked = !checkedTableNames.includes('_JourneyActivity');
    
        return isGroup8Checked && isGroup5Checked && isGroup4NotChecked
        ? `LEFT JOIN _JourneyActivity\n    ON SendLog.TriggeredSendID = _JourneyActivity.JourneyActivityObjectID\nLEFT JOIN _Journey\n    ON _JourneyActivity.VersionID = _Journey.VersionID` //\nLEFT JOIN _Journey ON _JourneyActivity.VersionID = _Journey.VersionID
        : '';
      },
        rule9: (baseTable, checkedTableNames) => {
           const isGroup8Checked = checkedTableNames.includes('SMSSendLog');
           const isMTNotChecked = !checkedTableNames.includes('_SMSMessageTracking');
           const isLogChecked = checkedTableNames.includes('_SMSSubscriptionLog');
           const isUndeliv = checkedTableNames.includes('_UndeliverableSms');
   
           return isGroup8Checked && isMTNotChecked && isLogChecked && isUndeliv
           ? `LEFT JOIN _SMSMessageTracking\n    ON SMSSendLog.SubID = _SMSMessageTracking.SubscriberID\n    AND SMSSendLog.SMSJobID = _SMSMessageTracking.SMSJobID\n    AND SMSSendLog.SMSBatchID = _SMSMessageTracking.SMSBatchID\nLEFT JOIN _SMSSubscriptionLog\n    ON _SMSMessageTracking.Mobile = _SMSSubscriptionLog.MobileNumber\nLEFT JOIN _UndeliverableSms\n    ON _SMSMessageTracking.Mobile = _UndeliverableSms.MobileNumber`
           : '';
         },
         rule91: (baseTable, checkedTableNames) => {
            const isGroup8Checked = checkedTableNames.includes('SMSSendLog');
            const isMTNotChecked = !checkedTableNames.includes('_SMSMessageTracking');
            const isLogNotChecked = !checkedTableNames.includes('_SMSSubscriptionLog');
            const isUndeliv = checkedTableNames.includes('_UndeliverableSms');
    
            return isGroup8Checked && isMTNotChecked && isLogNotChecked && isUndeliv
            ? `LEFT JOIN _SMSMessageTracking\n    ON SMSSendLog.SubID = _SMSMessageTracking.SubscriberID\n    AND SMSSendLog.SMSJobID = _SMSMessageTracking.SMSJobID\n    AND SMSSendLog.SMSBatchID = _SMSMessageTracking.SMSBatchID\nLEFT JOIN _UndeliverableSms\n    ON _SMSMessageTracking.Mobile = _UndeliverableSms.MobileNumber`
            : '';
          },
          rule92: (baseTable, checkedTableNames) => {
             const isGroup8Checked = checkedTableNames.includes('SMSSendLog');
             const isMTNotChecked = !checkedTableNames.includes('_SMSMessageTracking');
             const isLogChecked = checkedTableNames.includes('_SMSSubscriptionLog');
             const isUndelivNot = !checkedTableNames.includes('_UndeliverableSms');
     
             return isGroup8Checked && isMTNotChecked && isLogChecked && isUndelivNot
             ? `LEFT JOIN _SMSMessageTracking\n    ON SMSSendLog.SubID = _SMSMessageTracking.SubscriberID\n    AND SMSSendLog.SMSJobID = _SMSMessageTracking.SMSJobID\n    AND SMSSendLog.SMSBatchID = _SMSMessageTracking.SMSBatchID\nLEFT JOIN _SMSSubscriptionLog\n    ON _SMSMessageTracking.Mobile = _SMSSubscriptionLog.MobileNumber`
             : '';
           }
    };

    function isValidJoinCondition(joinCondition, tableStructures, baseTable, t) {
      const baseTableFields = joinCondition.match(new RegExp(`${baseTable}\\.\\w+`, 'g')) || [];
      const tFields = joinCondition.match(new RegExp(`${t}\\.\\w+`, 'g')) || [];
    
      const baseTableValid = baseTableFields.every(field => {
          const fieldName = field.split('.')[1];
          return tableStructures[baseTable]?.includes(fieldName);
      });
    
      const tValid = tFields.every(field => {
          const fieldName = field.split('.')[1];
          return tableStructures[t]?.includes(fieldName);
      });
    
      if (!baseTableValid || !tValid) {
          //console.log(`Skipped join due to invalid field in join condition: ${joinCondition}`);
          return false;
      }
      return true;
    }
    
    // Function to normalize join conditions for comparison
    function normalizeJoinCondition(joinCondition) {
      const match = joinCondition.match(/ON\s+(.+?)\s+=\s+(.+)/);
      if (match) {
        // Sort the conditions to handle different orderings and ignore extra spaces
        const [leftPart, rightPart] = [match[1].trim(), match[2].trim()].sort();
        return `ON ${leftPart} = ${rightPart}`;
      }
      return joinCondition;
    }
    
    // Function to remove duplicate join conditions with logging of removed duplicates
    function duplicateJoinConditions(joinStatement) {
      const joinLines = joinStatement.split('\n');
      const uniqueJoins = new Set();
      let filteredJoins = [];
      let currentJoin = [];
    
      joinLines.forEach((line, index) => {
        if (line.trim().startsWith("LEFT JOIN")) {
          // If a new LEFT JOIN starts, process the previous join block
          if (currentJoin.length > 0) {
            const normalizedCondition = normalizeJoinCondition(currentJoin.join(' '));
            if (!uniqueJoins.has(normalizedCondition)) {
              uniqueJoins.add(normalizedCondition);
              filteredJoins = filteredJoins.concat(currentJoin);
            } else {
              //console.log(`Removed duplicate join condition: ${currentJoin.join(' ')}`);
            }
            currentJoin = []; // Reset for the next join block
          }
        }
        currentJoin.push(line);
    
        // If it's the last line and it's part of a join block, process it
        if (index === joinLines.length - 1 && currentJoin.length > 0) {
          const normalizedCondition = normalizeJoinCondition(currentJoin.join(' '));
          if (!uniqueJoins.has(normalizedCondition)) {
            uniqueJoins.add(normalizedCondition);
            filteredJoins = filteredJoins.concat(currentJoin);
          } else {
            //console.log(`Removed duplicate join condition: ${currentJoin.join(' ')}`);
          }
        }
      });
    
      return filteredJoins.join('\n'); // Rejoin with newlines
    }
    
    // Function to sort LEFT JOIN conditions based on predefined order
    function sortJoinConditions(joinStatement) {
      const joinLines = joinStatement.split('\n');
      const joinGroups = [];
      let currentGroup = [];
    
      // Group each LEFT JOIN block together
      joinLines.forEach(line => {
        if (line.trim().startsWith("LEFT JOIN")) {
          if (currentGroup.length > 0) {
            joinGroups.push(currentGroup);
            currentGroup = [];
          }
        }
        currentGroup.push(line);
      });
      if (currentGroup.length > 0) {
        joinGroups.push(currentGroup);
      }
    
      // Sort the groups based on predefined order
      joinGroups.sort((a, b) => {
        const tableA = a[0].match(/LEFT JOIN (\w+)/)[1];
        const tableB = b[0].match(/LEFT JOIN (\w+)/)[1];
        return joinTables.indexOf(tableA) - joinTables.indexOf(tableB);
      });
    
      return joinGroups.flat().join('\n');
    }
    
    const constructJoinStatement = (checkedTableNames, baseTable) => {
      let joinStatement = '';
      let joinedTables = new Set([baseTable]); // Keep track of tables that have been joined
    
      // Check and apply special join rules
      const specialJoin = applySpecialJoinRules(baseTable, checkedTableNames);
      if (specialJoin) {
          joinStatement += specialJoin;
      }
    
      // Apply standard join conditions for tables not covered by special join
      checkedTableNames.forEach(t => {
          if (!joinedTables.has(t)) {
              const group = determineGroup(t);
              const joinCondition = groupJoinConditions[group] ? groupJoinConditions[group](baseTable, t) : '';
              if (isValidJoinCondition(joinCondition, tableStructures, baseTable, t)) {
                  joinStatement += `\nLEFT JOIN ${t} ${joinCondition}`;
                  joinedTables.add(t);
              }
          }
      });
    
      joinStatement = duplicateJoinConditions(joinStatement).trim(); // Remove trailing newline
      return sortJoinConditions(joinStatement); // Sort the JOIN conditions before returning
    };
    
    // Function to apply special join rules
    function applySpecialJoinRules(baseTable, checkedTableNames) {
      for (const rule in specialJoinRules) {
        const ruleCondition = specialJoinRules[rule];
        if (ruleCondition(baseTable, checkedTableNames)) {
          //console.log(`Applying special join condition: ${rule}`);
          setSpecialJoinApplied(true); // Update state to true when a rule is applied
          return ruleCondition(baseTable, checkedTableNames);
        }
      }
      setSpecialJoinApplied(false); // Update state to false when no rule is applied
      return null;
    }
    
    // Construct SELECT statement with specific field aliasing
    const constructSelectStatement = (tableNames) => {
      let selectStatement = '';
      tableNames.forEach(tableName => {
        tableStructures[tableName].forEach(field => {
          if (field !== 'IsUnique') { // Exclude 'IsUnique' field
            // Check if tableName starts with an underscore
            const aliasPrefix = tableName.startsWith('_') ? tableName.substring(1) : tableName;
    
            // Create the alias
            const fieldAlias = `${aliasPrefix}${field}`;
    
            // Check if field needs to be aliased
            if (fieldsAlwaysAliased.includes(field)) {
              selectStatement += `${tableName}.${field} AS ${fieldAlias},\n    `;
            } else {
              selectStatement += `${tableName}.${field},\n    `;
            }
          }
        });
      });
      return selectStatement;
    };
    
    // Function to optimize the SELECT statement by removing duplicate fields
    function optimizeSelectStatement(selectStatement) {
      const lines = selectStatement.split('\n');
      const seenFields = new Set();
      const optimizedLines = [];
    
      lines.forEach(line => {
        const parts = line.split('.');
        if (parts.length === 2) {
          const fieldParts = parts[1].trim().split(' ');
          const fieldName = fieldParts[0]; // Get field name before any aliasing
          const isAliased = fieldParts.length > 1 && fieldParts[1] === 'AS';
    
          if (!seenFields.has(fieldName) || isAliased) {
            seenFields.add(fieldName);
            optimizedLines.push(line);
          }
        } else {
          optimizedLines.push(line); // Keep lines that don't follow the pattern
        }
      });
    
      return optimizedLines.join('\n').trimEnd();
    }
    
    // Main function to construct the query
    const constructQuery = () => {
      let checkedTableNames = Object.keys(checkedTables).filter(tableName => checkedTables[tableName]);
      if (checkedTableNames.length === 0) return '';
    
      checkedTableNames.sort((a, b) => joinTables.indexOf(a) - joinTables.indexOf(b));
    
      let baseTable = checkedTableNames[0];
      let selectStatement = constructSelectStatement(checkedTableNames);
      selectStatement = optimizeSelectStatement(selectStatement); // Optimize to remove duplicates
      selectStatement = selectStatement.slice(0, -1); // Remove the trailing comma
      let fromStatement = `FROM ${baseTable}`;
      let joinStatement = constructJoinStatement(checkedTableNames, baseTable);
    
      const finalQuery = `SELECT\n    ${selectStatement}\n${fromStatement}\n${joinStatement}`;
    
      const modifiedQuery = finalQuery
      .replace(/FROM SendLog/g, 'FROM [SendLogDEname] AS SendLog    /* replace SendLogDEname with your SendLog DE name */')
      .replace(/LEFT JOIN SendLog/g, 'LEFT JOIN [SendLogDEname] AS SendLog    /* replace SendLogDEname with your SendLog DE name */')
      .replace(/FROM SMSSendLog/g, 'FROM [SmsSendLogDEname] AS SMSSendLog    /* replace SmsSendLogDEname with your SmsSendLog DE name */')
      .replace(/LEFT JOIN SMSSendLog/g, 'LEFT JOIN [SmsSendLogDEname] AS SMSSendLog    /* replace SmsSendLogDEname with your SmsSendLog DE name */');
    
      console.log(modifiedQuery);
      //return modifiedQuery;
    
      //console.log(finalQuery);
      return modifiedQuery;
    };
    
    const newQuery = constructQuery();
    updateQuery(newQuery);
    
    }, [checkedTables, updateQuery, tableStructures]);
    
    useEffect(() => {
      if (specialJoinApplied !== null) {
        onSpecialJoinApplied(specialJoinApplied);
      }
    }, [specialJoinApplied, onSpecialJoinApplied]);
    
    return null;
    };
    
    export default QueryGenerator;
    