import React from 'react';
import Table from './Table';

const Family = ({ family, tables, isZoomed, highlightedValues, updateHighlightedValues, isDarkMode, onToggleCheckbox, checkedTables, disabledTables }) => {
  return (
    <div className="family">
      <h2 className="family-name">{family}</h2>
      <div className="tables">
        {tables.map((table) => (
          <Table
            key={table.name}
            tableData={table}
            isZoomed={isZoomed}
            highlightedValues={highlightedValues}
            updateHighlightedValues={updateHighlightedValues}
            isDarkMode={isDarkMode}
            onToggleCheckbox={() => onToggleCheckbox(table.name)}
            checked={checkedTables[table.name] || false} // Pass checked state to Table component
            disabled={disabledTables[table.name] || false} // Pass disabled state to Table component
          />
        ))}
      </div>
    </div>
  );
};

export default Family;
