import React, { useState } from 'react';

const About = ({ isDarkMode, className }) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={`about-container${isDarkMode ? " dark-mode" : ""} ${className}`}>
      <div onClick={toggleCollapse} className="about-header">
      <h2 class="family-name"><span className={`about-icon-container${isDarkMode ? " dark-mode" : ""}`}>
  <span className={`about-icon`} style={{ transform: collapsed ? 'rotate(0)' : 'rotate(90deg)', display: 'inline-block', transition: 'transform 0.2s' }}>&#9654;</span>
</span>About</h2>
      </div>
      {!collapsed && (
        <div className="about-content">
          <p>
            Data Views in Salesforce Marketing Cloud are a powerful set of
            system-generated tables that provide insights into your marketing
            efforts. They enable users to access and analyze essential
            information about subscribers, tracking data, and more. With Data
            Views, marketers can create targeted segments, monitor campaign
            performance, and optimize their strategies to improve customer
            engagement.         
 <br />
          <br />
          This website is built to make it easy for you to explore all the data in Data Views, including table fields and relationships between tables. It's designed to help you quickly copy and paste table and field names when creating SQL queries. This saves you time and effort, as you won't have to search through multiple pages of documentation. 
          All table and field descriptions come from the{' '}
            <a
              href="https://help.salesforce.com/s/articleView?id=sf.mc_as_data_views.htm&type=5"
              target="_blank"
              rel="noopener noreferrer"
            >
              official documentation
            </a>.
            <br />
          <br />
          User guide:
          <br />
          - Press Ctrl - (minus) to zoom out to see more tables on the screen simultaneously. 
          <br />
          - Click on 'Display details' to see all table field descriptions and their datatypes.
          <br />
          - Hover your cursor over a field in a table to see all the other tables in which that same field is present.
          <br />
          - Type a field name into the search box to see all the tables in which this field is present.
          <br />
          - To generate an SQL query, select the required tables by checking their checkboxes, click 'Generate Query' to copy the SQL query to your clipboard, and run it in Automation Studio or Query Studio; if it times out, try limiting the date range. In case selected tables don't share common fields, the system will automatically include extra tables in the join section to enable the join.<br />
          <br />
          This website is still under construction. If you have any feedback or suggestions, please contact{' '}
          <a href="mailto:info@dataviews.io">info@dataviews.io</a>.
          <br />
          <br />
          © 2023-{new Date().getFullYear()} Zuzanna Jarczynska. All rights reserved.</p></div>
      )}
    </div>
  );
};

export default About;
