import React, { useState, useRef } from "react";

function SearchBox({ fields, onSearch }) {
  const [inputValue, setInputValue] = useState("");
  const [filteredFields, setFilteredFields] = useState([]);
  const [itemClicked, setItemClicked] = useState(false);
  const dropdownRef = useRef(null);

  const consolidateFields = (fields) => {
    const fieldMap = new Map();

    fields.forEach((field) => {
      const nameKey = field.name.replace(/^_/, "");
      const existing = fieldMap.get(nameKey);

      if (existing) {
        existing.count += field.count;
      } else {
        fieldMap.set(nameKey, { ...field, name: nameKey, originalName: field.name });
      }
    });

    return Array.from(fieldMap.values());
  };

  const handleInputChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setInputValue(searchTerm);
    setItemClicked(false);

    const consolidated = consolidateFields(fields);
    const filtered = consolidated
      .filter((field) => field.name.toLowerCase().includes(searchTerm))
      .sort((a, b) => b.count - a.count);

    setFilteredFields(filtered);
    
    // Call the passed in onSearch prop to update the search term in App.js
    onSearch(searchTerm);
  };

  const handleInputFocus = () => {
    setItemClicked(false);
    if (inputValue === "") {
      const consolidated = consolidateFields(fields);
      setFilteredFields(consolidated.sort((a, b) => b.count - a.count));
    }
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      if (dropdownRef.current && !dropdownRef.current.contains(document.activeElement)) {
        setFilteredFields([]);
      }
    }, 100);
  };

  const handleItemClick = (field) => {
    const searchValue = `${field.originalName} (${field.count})`;
    setInputValue(searchValue);
    setFilteredFields([]);
    setItemClicked(true);

    // Update the search term in App.js
    onSearch(field.originalName); // Assuming you want the original name without the count for searching
  };

  const clearSearch = () => {
    setInputValue("");
    setFilteredFields([]);
    setItemClicked(false);
    onSearch(""); // Reset the search term in App.js
  };

  const highlightSearchTerm = (name, term) => {
    const replaceRegex = new RegExp(`(${term})`, "gi");
    return name.replace(replaceRegex, "<b>$1</b>");
  };

  const isInputAFieldItem = () => {
    return fields.some(field => `${field.originalName} (${field.count})` === inputValue);
  };

  return (
    <div style={{ paddingLeft: '1rem', paddingBottom: '1rem' }}>
    <div className="search-container">
      <input
        className="search-input"
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        placeholder="Search fields..."
      />
      {inputValue && (
        <button
          onClick={clearSearch}
          aria-label="Clear search"
          className="clear-search"
        >
          &times;
        </button>
      )}
    </div>
    {(filteredFields.length > 0 || inputValue) && (
      <div className={`dropdown-list ${itemClicked ? 'hidden' : ''}`} ref={dropdownRef}>
        {filteredFields.length > 0 ? (
          filteredFields.map((field) => (
            <button
              key={field.originalName}
              onClick={() => handleItemClick(field)}
              style={{ cursor: "pointer", background: "none", border: "none", textAlign: "left", display: "block" }}
            >
              <span
                className="field-name"
                dangerouslySetInnerHTML={{
                  __html: highlightSearchTerm(field.originalName, inputValue),
                }}
              ></span>
              <span className="field-count">({field.count})</span>
            </button>
          ))
        ) : !itemClicked && !isInputAFieldItem() && (
          <div style={{ padding: "8px 12px", color: "#888" }}>No results found</div>
        )}
      </div>
    )}
  </div>
);
}

export default SearchBox;
