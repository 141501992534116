import React, { useState, useEffect, createContext } from "react";
import { fetchData } from "./dataFetcher";
import Family from "./components/Family";
import About from './components/About';
import SearchBox from './components/SearchBox';
import QueryGenerator from './components/QueryGenerator';
import "./App.css";

export const ThemeContext = createContext();

function App() {
  const [data, setData] = useState([]);
  const [isZoomed, setIsZoomed] = useState(() => JSON.parse(localStorage.getItem("displayDetails")) || false);
const [isDarkMode, setIsDarkMode] = useState(() => JSON.parse(localStorage.getItem("darkMode")) || false);
const [displaySendLogs, setDisplaySendLogs] = useState(() => JSON.parse(localStorage.getItem("displaySendLogs")) || false);
const [displaySFdes, setDisplaySFdes] = useState(() => JSON.parse(localStorage.getItem("displaySFdes")) || false);
  const [searchTerm, setSearchTerm] = useState(""); 
  const [highlightedValues, setHighlightedValues] = useState("");
  const [checkedTables, setCheckedTables] = useState({});
  const [disabledTables, setDisabledTables] = useState({});
  const [query, setQuery] = useState(""); 
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [tableFields, setTableFields] = useState({});
  const [specialJoinApplied, setSpecialJoinApplied] = useState(false);
  const [showConflictToast, setShowConflictToast] = useState(false);

  useEffect(() => {
    fetchData().then(fetchedData => {
      const excludedFamilies = [];
      if (!displaySendLogs) {
        excludedFamilies.push("SendLog Templates");
      }
  
      if (!displaySFdes) {
        excludedFamilies.push("Synchronized Data Extensions");
      }
  
      let filteredData = fetchedData.filter(table => 
        !excludedFamilies.includes(table.family)
      );
  
      setData(filteredData);

      
      // Uncheck tables that are no longer in the data

      setCheckedTables(prevCheckedTables => {

        const updatedCheckedTables = { ...prevCheckedTables };

        Object.keys(updatedCheckedTables).forEach(tableName => {

          if (!filteredData.some(table => table.name === tableName)) {

            updatedCheckedTables[tableName] = false;

          }

        });

        return updatedCheckedTables;

      });
  
    });
  }, [displaySendLogs, displaySFdes]); // Both dependencies in one array
  

useEffect(() => {
  let conflictDetected = false; // Track if a conflict was detected

  Object.keys(disabledTables).forEach(table => {
    if (disabledTables[table] && checkedTables[table]) {
      setCheckedTables(prevCheckedTables => ({
        ...prevCheckedTables,
        [table]: false, // Uncheck the disabled table
      }));
      conflictDetected = true; // Set to true when a conflict is found
    }
  });

  if (conflictDetected) {
    setShowConflictToast(true); // Show the toast message

    // Hide the toast after 3 seconds
    setTimeout(() => setShowConflictToast(false), 3000);
  }
}, [disabledTables, checkedTables]); // Trigger this effect whenever disabledTables or checkedTables changes


  useEffect(() => {
    fetchData().then(fetchedData => {
      const transformedData = fetchedData.reduce((acc, table) => {
        acc[table.name] = table.fields.map(field => field.field);
        return acc;
      }, {});
      setTableFields(transformedData);
    });
  }, []);

  // Persist isZoomed state to localStorage
useEffect(() => {
  localStorage.setItem("displayDetails", JSON.stringify(isZoomed));
}, [isZoomed]);

// Persist dark mode state to localStorage
useEffect(() => {
  localStorage.setItem("darkMode", JSON.stringify(isDarkMode));
  document.body.classList.toggle('dark-mode', isDarkMode); // Apply dark mode to body
}, [isDarkMode]);

// Persist displaySendLogs state to localStorage
useEffect(() => {
  localStorage.setItem("displaySendLogs", JSON.stringify(displaySendLogs));
}, [displaySendLogs]);

// Persist displaySFdes state to localStorage
useEffect(() => {
  localStorage.setItem("displaySFdes", JSON.stringify(displaySFdes));
}, [displaySFdes]);


  useEffect(() => {
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
    if (prefersDarkScheme.matches) {
      setIsDarkMode(true);
      document.body.classList.add('dark-mode'); 
    }
  }, []);

  const groupedData = data.reduce((result, item) => {
    (result[item.family] = result[item.family] || []).push(item);
    return result;
  }, {});

  const fieldsMap = data.reduce((acc, table) => {
    table.fields.forEach(field => {
      const fieldName = field.field;
      acc[fieldName] = (acc[fieldName] || 0) + 1;
    });
    return acc;
  }, {});

  const fieldsData = Object.entries(fieldsMap).map(([name, count]) => ({ name, count }));

  const handleSearch = (value) => {
    setSearchTerm(value);
    setHighlightedValues(value);
  };

  const filteredGroupedData = Object.entries(groupedData).reduce((acc, [family, tables]) => {
    const filteredTables = tables.filter(table => 
      table.fields.some(field => field.field.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    if (filteredTables.length > 0) {
      acc[family] = filteredTables;
    }
    return acc;
  }, {});

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('dark-mode');
  };

  const handleToggleCheckbox = (tableName) => {
    setCheckedTables((prevCheckedTables) => {
      const isChecked = !prevCheckedTables[tableName];
      const newCheckedTables = {
        ...prevCheckedTables,
        [tableName]: isChecked,
      };
      console.log("CheckedTables Updated:", newCheckedTables);
      updateDisabledTables(newCheckedTables, tableName, isChecked);
      return newCheckedTables;
    });
  };
  

  const updateDisabledTables = (checkedTables, tableName, isChecked) => {
    // Define group mappings
    const groups = {
        'group0': ['SendLog'],
        'group1': ['_Sent', '_Open', '_Bounce', '_Click', '_FTAF'],
        'group2': ['_Unsubscribe', '_Complaint'],
        'group3': ['_BusinessUnitUnsubscribes', '_Subscribers', '_ListSubscribers'],
        'group4': ['_JourneyActivity'],
        'group5': ['_Journey'],
        'group6': ['_Job'],
        'group7': ['_AutomationInstance', '_AutomationActivityInstance'],
        'group8': ['SMSSendLog'],
        'group9': ['_SMSMessageTracking', '_SMSSubscriptionLog', '_UndeliverableSms']
    };

    const tableToGroup = {};
    Object.keys(groups).forEach(group => {
        groups[group].forEach(table => {
            tableToGroup[table] = group;
        });
    });

    const isGroupChecked = (group) => {
        return Object.keys(checkedTables).some(table => checkedTables[table] && tableToGroup[table] === group);
    };

    const newDisabledTables = {};

    // Primary Rule: If any table in group 7 is checked, disable all other groups (0-6, 8-9)
    if (isGroupChecked('group7')) {
        Object.keys(tableToGroup).forEach(table => {
            if (tableToGroup[table] !== 'group7') {
                newDisabledTables[table] = true;
            }
        });
    } else {
        // Secondary Rule: If any table in groups 8 or 9 is checked, disable groups 0-7
        if (isGroupChecked('group8') || isGroupChecked('group9')) {
            Object.keys(tableToGroup).forEach(table => {
                if (tableToGroup[table] !== 'group8' && tableToGroup[table] !== 'group9') {
                    newDisabledTables[table] = true;
                }
            });
        } else {
            // Re-enable groups 0-7 if no tables in groups 8 or 9 are checked
            Object.keys(tableToGroup).forEach(table => {
                if (tableToGroup[table] !== 'group8' && tableToGroup[table] !== 'group9') {
                    newDisabledTables[table] = false;
                }
            });

            // Tertiary Rule: If any table in groups 0-6 is checked, disable groups 7, 8, and 9
            if (isGroupChecked('group0') || isGroupChecked('group1') || isGroupChecked('group2') ||
                isGroupChecked('group3') || isGroupChecked('group4') || isGroupChecked('group5') || isGroupChecked('group6')) {
                Object.keys(tableToGroup).forEach(table => {
                    if (tableToGroup[table] === 'group7' || tableToGroup[table] === 'group8' || tableToGroup[table] === 'group9') {
                        newDisabledTables[table] = true;
                    }
                });
            } else {
                // Re-enable groups 7, 8, and 9 if no tables in groups 0-6 are checked
                Object.keys(tableToGroup).forEach(table => {
                    if (tableToGroup[table] === 'group7' || tableToGroup[table] === 'group8' || tableToGroup[table] === 'group9') {
                        newDisabledTables[table] = false;
                    }
                });
            }
        }
    }

    console.log("New Disabled Tables:", newDisabledTables);
    setDisabledTables(newDisabledTables);
};


  
  const updateQuery = (newQuery) => {
    setQuery(newQuery);
  };

  const handleGenerateQuery = () => {
    navigator.clipboard.writeText(query).then(() => {
      setShowCopySuccess(true);
      setTimeout(() => setShowCopySuccess(false), 3000);
    });
  };
  
  const isGenerateButtonDisabled = !Object.values(checkedTables).some(isChecked => isChecked);

  function handleSpecialJoinStatus(isApplied) {
    setSpecialJoinApplied(isApplied);
  }

  return (
    <ThemeContext.Provider value={{ isDarkMode }}>
      <div className={`App ${isDarkMode ? "dark-mode" : "light-mode"}`}>
        <About isDarkMode={isDarkMode} className="about-margin-bottom" />
        {showConflictToast && (
        <div className="toast-notification">
Some tables have been deselected because they have no common fields with the currently selected tables. Please review your selection.        
</div>
      )}
        <div className="header-container">
        <SearchBox fields={fieldsData} onSearch={handleSearch} style={{ flexGrow: 1 }} />
        <div className="toggles-container">
          <div className="toggles">
          <label>
  <span>Display details</span>
  <div className="switch">
    <input 
      type="checkbox" 
      checked={isZoomed}
      onChange={toggleZoom} 
    />
    <span className="slider"></span>
  </div>
</label>

            <label>
              <span>Dark mode</span>
              <div className="switch">
                <input type="checkbox" checked={isDarkMode} onChange={toggleDarkMode} />
                <span className="slider"></span>
              </div>
            </label>
            <label>
  <span>Display SendLog Templates</span>
  <div className="switch">
    <input 
      type="checkbox" 
      checked={displaySendLogs} 
      onChange={() => {
        setDisplaySendLogs((prevDisplaySendLogs) => {
          const newDisplaySendLogs = !prevDisplaySendLogs;
        
          // Update checkedTables and disabledTables together
          const updatedCheckedTables = {
            ...checkedTables,
            SendLog: false,
          };

          // Set the new checked tables state
          setCheckedTables(updatedCheckedTables);

          // Update disabled tables based on the new state of checked tables
          updateDisabledTables(updatedCheckedTables, 'SendLog', false);

          console.log("DisplaySendLogs Toggled:", newDisplaySendLogs);
          return newDisplaySendLogs;
        });
      }} 
    />
    <span className="slider"></span>
  </div>
</label>

<label>
  <span>Display Synchronized Data Extensions</span>
  <div className="switch">
    <input 
      type="checkbox" 
      checked={displaySFdes} 
      onChange={() => {
        setDisplaySFdes((prevDisplaySFdes) => {
          const newDisplaySFdes = !prevDisplaySFdes;

          return newDisplaySFdes;
        });
      }} 
    />
    <span className="slider"></span>
  </div>
</label>
          </div>
          <div style={{ paddingLeft: '1rem', paddingBottom: '1rem' }}>
            <button
              disabled={isGenerateButtonDisabled} 
              onClick={handleGenerateQuery}
              className={`generate ${isGenerateButtonDisabled ? "disabled-button" : ""}`}
            >
              Generate Query
            </button>
          </div>
          </div>
          {showCopySuccess && (
            <div className="toast-notification">
              Query has been saved to clipboard. 
              {specialJoinApplied ? " NOTE: Additional tables added for valid joins due to missing common fields." : ""}
            </div>
          )}
          <QueryGenerator 
            checkedTables={checkedTables}
            updateQuery={setQuery}
            tableStructures={tableFields}
            onSpecialJoinApplied={handleSpecialJoinStatus}
          />
        </div>
        <div className="families">
          {Object.entries(filteredGroupedData).map(([family, tables]) => (
            <Family
              key={family}
              family={family}
              tables={tables}
              isZoomed={isZoomed}
              highlightedValues={highlightedValues}
              updateHighlightedValues={setHighlightedValues}
              onToggleCheckbox={handleToggleCheckbox}
              checkedTables={checkedTables} // Pass checked state down
              disabledTables={disabledTables} // Pass disabled state down
            />
          ))}
        </div>
        <br />
        <footer>
          ver 2.909 &copy; 2023-{new Date().getFullYear()} Zuzanna Jarczynska. All rights reserved.
        </footer>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;