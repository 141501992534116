import React, { useEffect, useRef, useState } from 'react';

const tablesWithCheckboxes = [
  '_Sent',
  '_Open',
  '_Bounce',
  '_Click',
  '_Unsubscribe',
  '_FTAF',
  '_Complaint',
  '_BusinessUnitUnsubscribes',
  '_Job',
  '_Subscribers',
  '_ListSubscribers',
  '_Journey',
  '_JourneyActivity',
  '_AutomationInstance',
  '_AutomationActivityInstance',
  'SendLog',
  'SMSSendLog',
  '_SMSMessageTracking',
  '_SMSSubscriptionLog',
  '_UndeliverableSms'
];

const getHighlightClass = (cellValue, isDarkMode) => {
  const highlightMapping = {
    'JobID': isDarkMode ? 'dark-highlight-jobid' : 'highlight-jobid',
    'SubscriberID': isDarkMode ? 'dark-highlight-SubscriberID' : 'highlight-SubscriberID',
    '_SubscriberID': isDarkMode ? 'dark-highlight-SubscriberID' : 'highlight-SubscriberID',
    'SubID': isDarkMode ? 'dark-highlight-SubscriberID' : 'highlight-SubscriberID',
    'SubscriberKey': isDarkMode ? 'dark-highlight-SubscriberKey' : 'highlight-SubscriberKey',
    'Mobile': isDarkMode ? 'dark-highlight-Mobile' : 'highlight-Mobile',
    'MobileNumber': isDarkMode ? 'dark-highlight-Mobile' : 'highlight-Mobile',
    '_MobileNumber': isDarkMode ? 'dark-highlight-Mobile' : 'highlight-Mobile',
    'TriggererSendDefinitionObjectID': isDarkMode ? 'dark-highlight-TriggererSendDefinitionObjectID' : 'highlight-TriggererSendDefinitionObjectID',
    'JourneyActivityObjectID': isDarkMode ? 'dark-highlight-TriggererSendDefinitionObjectID' : 'highlight-TriggererSendDefinitionObjectID',
    'VersionID': isDarkMode ? 'dark-highlight-VersionID' : 'highlight-VersionID',
    'AutomationCustomerKey': isDarkMode ? 'dark-highlight-AutomationCustomerKey' : 'highlight-AutomationCustomerKey',
    'ContactID': isDarkMode ? 'dark-highlight-ContactID' : 'highlight-ContactID',
    'ContactKey': isDarkMode ? 'dark-highlight-ContactKey' : 'highlight-ContactKey',
    '_ContactID': isDarkMode ? 'dark-highlight-ContactID' : 'highlight-ContactID',
    '_ContactKey': isDarkMode ? 'dark-highlight-ContactKey' : 'highlight-ContactKey',
    '_DeviceID': isDarkMode ? 'dark-highlight-_DeviceID' : 'highlight-_DeviceID',
    'JBActivityID': isDarkMode ? 'dark-highlight-JBactivityID' : 'highlight-JBactivityID',
    'ActivityID': isDarkMode ? 'dark-highlight-JBactivityID' : 'highlight-JBactivityID',
    'JobID,': isDarkMode ? 'dark-highlight-jobid' : 'highlight-jobid',
    'SubscriberID,': isDarkMode ? 'dark-highlight-SubscriberID' : 'highlight-SubscriberID',
    '_SubscriberID,': isDarkMode ? 'dark-highlight-SubscriberID' : 'highlight-SubscriberID',
    'SubscriberKey,': isDarkMode ? 'dark-highlight-SubscriberKey' : 'highlight-SubscriberKey',
    'Mobile,': isDarkMode ? 'dark-highlight-Mobile' : 'highlight-Mobile',
    'MobileNumber,': isDarkMode ? 'dark-highlight-Mobile' : 'highlight-Mobile',
    '_MobileNumber,': isDarkMode ? 'dark-highlight-Mobile' : 'highlight-Mobile',
    'TriggererSendDefinitionObjectID,': isDarkMode ? 'dark-highlight-TriggererSendDefinitionObjectID' : 'highlight-TriggererSendDefinitionObjectID',
    'JourneyActivityObjectID,': isDarkMode ? 'dark-highlight-TriggererSendDefinitionObjectID' : 'highlight-TriggererSendDefinitionObjectID',
    'VersionID,': isDarkMode ? 'dark-highlight-VersionID' : 'highlight-VersionID',
    'AutomationCustomerKey,': isDarkMode ? 'dark-highlight-AutomationCustomerKey' : 'highlight-AutomationCustomerKey',
    'ContactID,': isDarkMode ? 'dark-highlight-ContactID' : 'highlight-ContactID',
    'ContactKey,': isDarkMode ? 'dark-highlight-ContactKey' : 'highlight-ContactKey',
    '_ContactID,': isDarkMode ? 'dark-highlight-ContactID' : 'highlight-ContactID',
    '_ContactKey,': isDarkMode ? 'dark-highlight-ContactKey' : 'highlight-ContactKey',
    '_DeviceID,': isDarkMode ? 'dark-highlight-_DeviceID' : 'highlight-_DeviceID',
    'JBActivityID,': isDarkMode ? 'dark-highlight-JBactivityID' : 'highlight-JBactivityID',
    'ActivityID,': isDarkMode ? 'dark-highlight-JBactivityID' : 'highlight-JBactivityID',
    'SMSJobID': isDarkMode ? 'dark-highlight-SMSJobID' : 'highlight-SMSJobID',
    'SMSBatchID': isDarkMode ? 'dark-highlight-SMSBatchID' : 'highlight-SMSBatchID'
  };

  return highlightMapping[cellValue] || '';
};


const Table = ({
  tableData,
  isZoomed,
  isDarkMode,
  highlightedValues,
  updateHighlightedValues,
  onToggleCheckbox,
  checked,
  disabled
}) => {
  const tableRef = useRef();
  const aboutRef = useRef();
  const [clickedValue] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (onToggleCheckbox) {
      onToggleCheckbox(tableData.name, event.target.checked);
    }
  };
  
  const shouldShowCheckbox = (tableName) => {
    return tablesWithCheckboxes.includes(tableName);
  };


  useEffect(() => {
    if (isZoomed && tableRef.current && aboutRef.current) {
      const tableWidth = tableRef.current.clientWidth;
      aboutRef.current.style.maxWidth = `${tableWidth}px`;
    }
  }, [isZoomed]);

  const handleMouseLeave = () => {
    updateHighlightedValues(null);
  };
  
  const relatedHighlightMapping = {
    'AccountID': ['MID','BusinessUnitID','MemberID'],
    'MID': ['AccountID','BusinessUnitID','MemberID'],
    'BusinessUnitID': ['MID','AccountID','MemberID'],
    'MemberID': ['MID','AccountID','BusinessUnitID'],
    'TriggererSendDefinitionObjectID': ['JourneyActivityObjectID'],
    'Mobile': ['MobileNumber','_MobileNumber'],
    'MobileNumber': ['Mobile','_MobileNumber'],
    '_MobileNumber': ['MobileNumber','Mobile'],
    '_SubscriberID': ['SubscriberID'],
    'JBActivityID': ['ActivityID'],
    'AccountID,': ['MID,','BusinessUnitID,','MemberID,','MID','BusinessUnitID','MemberID','AccountID'],
    'MID,': ['AccountID,','BusinessUnitID,','MemberID,','AccountID','BusinessUnitID','MemberID','MID'],
    'BusinessUnitID,': ['MID,','AccountID,','MemberID,','MID','AccountID','MemberID','BusinessUnitID'],
    'MemberID,': ['MID,','AccountID,','BusinessUnitID,','MID','AccountID','BusinessUnitID','MemberID'],
    'TriggererSendDefinitionObjectID,': ['JourneyActivityObjectID,','JourneyActivityObjectID','TriggererSendDefinitionObjectID'],
    'Mobile,': ['MobileNumber,','_MobileNumber,','MobileNumber','_MobileNumber','Mobile'],
    'MobileNumber,': ['Mobile,','_MobileNumber,','Mobile','_MobileNumber','MobileNumber'],
    '_MobileNumber,': ['MobileNumber,','Mobile,','MobileNumber','Mobile','_MobileNumber'],
    '_SubscriberID,': ['SubscriberID,','SubscriberID','_SubscriberID'],
    'JBActivityID,': ['ActivityID,','ActivityID','JBActivityID']
  };

  const getRelatedValues = (value) => {
    const relatedValues = [];
    for (const [key, values] of Object.entries(relatedHighlightMapping)) {
      if (key === value) {
        relatedValues.push(...values);
      }
      if (values.includes(value)) {
        relatedValues.push(key);
      }
    }
    return relatedValues;
  };
  
  const handleMouseEnter = (value) => {
    const relatedValues = getRelatedValues(value);
    updateHighlightedValues([value, ...relatedValues]);
  };
  
  const getAdditionalHighlightClass = (cellValue) => {
    return (
      (highlightedValues && highlightedValues.includes(cellValue)) ||
      clickedValue === cellValue
    )
      ? 'additional-highlight'
      : '';
  };

  return (
    <div className={`table ${isZoomed ? 'zoomed' : ''}`}>
          <div className="table-header">
      <h3 className="dv-name">{tableData.name}</h3>
      {shouldShowCheckbox(tableData.name) && (
    <input
    type="checkbox"
    checked={checked}
    onChange={handleCheckboxChange}
    disabled={disabled}
    className="table-checkbox"
    title={disabled ? "This checkbox is disabled because this table has no common fields with your selected table(s)." : ""}
  />
  )}
    </div>
      {isZoomed && tableData.about && <p ref={aboutRef}>{tableData.about}</p>}
      <div className="table-content">
        <table id="my-table" ref={tableRef}>
          <thead>
            <tr>
              {isZoomed && <th>Field</th>}
              {isZoomed && (
                <>
                  <th>Type</th>
                  <th>Description</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
          {tableData.fields.map((field, index) => (
              <tr key={field.field}>
                <td
                  className={`${getHighlightClass(
                    field.field,
                    isDarkMode,
                  )} ${getAdditionalHighlightClass(field.field)}`}
                  onMouseEnter={() => handleMouseEnter(field.field)}
                  onMouseLeave={handleMouseLeave}
                >
                  {field.field}
            {index < tableData.fields.length - 1 && <span className='comma'>,</span>}
        </td>
                {isZoomed && (
                  <>
                    <td>{field.type}</td>
                    <td>{field.description}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );  
};

export default Table;